import consumer from "./consumer"
import * as unit_wizard_functions from "././unit_functions/unit_wizard"
import * as unit_batch_functions from "././unit_functions/unit_batch"
import * as batch_sub_functions from "././subscription_functions/batch_sub"
import * as sub_functions from "././subscription_functions/generic_functions"
import * as payment_functions from "./payment_functions/payments"

consumer.subscriptions.create(
  {
    channel: "ProfilesChannel",
    room: room_name(),
  },
  {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log(`connected to room ${room_name()}`)
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
      console.log("disconnected")
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      console.log(`Received data on room: ${room_name()}`)
      // lets parse the data and show errors if we need to
      if (data.hasOwnProperty("jobtype")) {
        switch (data.jobtype) {
          case "unit_deploy_progress":
            unit_wizard_functions.unit_deploy_progress(data)
            break
          case "unit_deploy":
            unit_wizard_functions.parse_unit_wizard_data(data)
            break
          case "unit_csv_deploy":
            unit_wizard_functions.parse_unit_import_data(data)
            break
          case "unit_batch_process":
            unit_batch_functions.parse_unit_batch_data(data)
            break
          case "batch_subscription":
            batch_sub_functions.parse_batch_sub_data(data)
            break
          case "payment_notices":
            payment_functions.handle_payments_message(data)
            break
          case "generic_notices":
            sub_functions.parse_generic_sub_data(data)
            break
          default:
            console.log("Message type has not been sent!!!")
        }
      }
    },
  }
)

function room_name() {
  return `profiles_channel_${gon.cable_vars.tenant_schema}_${gon.cable_vars.p_slug}`
}
