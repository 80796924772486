import * as shared_functions from "../shared/shared_functions"

export function parse_generic_sub_data(data) {
    if (shared_functions.get_message_div("action_cable_div").length > 0) {
        console.log(`sub-generic:`, data)
        $('html, body').animate({ scrollTop: 0 }, 'fast');
        shared_functions.get_message_div("action_cable_div").html("")
        shared_functions.display_messages(data, "errors", "action_cable_div")
        shared_functions.display_messages(
            data,
            "messages",
            "action_cable_div"
        )
    }
    else {
        console.warn(`sub-generic: Failed to deliver`, data)
    }
}