// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
// This stim controller will insert a hidden field into a form
// with a unique browser fingerprint.  It will be used to identify
// trusted devices for MFA


import {Controller} from 'stimulus'
import Fingerprint from 'fingerprintjs'
export default class extends Controller {
    static targets = ['form']

    connect() {
        let stim = this
        let fp = stim.get_finger_print()
        if (fp){
            //console.log(fp)
            $(stim.formTargets).each(function (i, form) {
                let fp_field = $(form).find('input[id=fp]:hidden')
                let ctxt = {stim: stim, form: form,fp_field: fp_field, fp: fp}
                $(form).on('submit', ctxt, stim.add_fp_field)
            })
        }
    }

    get_finger_print(){
        let fingerprint = ''
        try {
            fingerprint = new Fingerprint().get()
        } catch (e) {
            console.error(`Finger print error`, e)
        }
        return fingerprint
    }

    add_fp_field(svt){
        let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
        if(ctxt.fp_field.length < 1){
            let fp = ctxt.fp
            $(`<input type="hidden" name="fp_field" value=${fp}>`).appendTo(ctxt.form)
        }
    }
}
